<template>
  <div data-wizard-type="step-content">
    <Vehicle
      v-if="policyType === constantsPolicyTypeAuto"
      :v="v.vehicle"
      :form="form.vehicle"
      :state="state.vehicle"
    />
    <House
      v-if="policyType === constantsPolicyTypeProperty"
      :v="v.house"
      :form="form.house"
      :state="state.house"
    />
  </div>
</template>

<script>
import Vehicle from "./vehicle/Vehicle";
import House from "./house/House";
import {CONSTANTS} from "@/core/helpers/Constants";
export default {
  name: "InsuranceObject",
  components: { Vehicle, House },
  props: ["policyType", "form", "v", "state"],
  computed: {
    constantsPolicyTypeAuto() {
      return CONSTANTS().insuranceGroupAuto;
    },
    constantsPolicyTypeProperty() {
      return CONSTANTS().insuranceGroupProperty;
    },
    constantsPolicyTypeLife() {
      return CONSTANTS().insuranceGroupLife;
    }
  }
};
</script>

<style scoped></style>
