<template>
  <div>
    <div class="row">
      <div class="col-lg-6">
        <div
          class="form-group"
          :class="isFieldInvalid('policyIssueDate') && 'invalid'"
        >
          <label for="policyIssueDate">Data wystawienia polisy</label>
          <Datepicker
            id="policyIssueDate"
            :form="form"
            name="policyIssueDate"
            @onChange="handleChangeDate($event, 'policyIssueDate')"
          />
          <div
            v-if="isFieldInvalid('policyIssueDate')"
            class="invalid-feedback"
          >
            Uzupełnij to pole
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="row">
          <div class="col-lg-6">
            <div
              class="form-group"
              :class="isFieldInvalid('startDate') && 'invalid'"
            >
              <label for="startDate">Start ochrony</label>
              <Datepicker
                id="startDate"
                :form="form"
                name="startDate"
                @onChange="handleChangeDate($event, 'startDate')"
                @changeCalendar="handleChangeStartDate"
              />
              <div v-if="isFieldInvalid('startDate')" class="invalid-feedback">
                Uzupełnij to pole
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div
              class="form-group"
              :class="isFieldInvalid('period') && 'invalid'"
            >
              <Select
                id="name"
                v-model="form.period"
                :options="getPeriods"
                label="Okres ochrony"
                name="period"
                @onChange="handleChangePeriod"
              />
              <div v-if="isFieldInvalid('period')" class="invalid-feedback">
                Uzupełnij to pole
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group" :class="isFieldInvalid('endDate') && 'invalid'">
          <label for="endDate">Koniec ochrony</label>
          <Datepicker
            id="endDate"
            :form="form"
            name="endDate"
            :disabled="isDisabledEndDate"
            @onChange="handleChangeDate($event, 'endDate')"
          />
          <div v-if="isFieldInvalid('endDate')" class="invalid-feedback">
            Uzupełnij to pole
          </div>
        </div>
      </div>
    </div>

    <Installments :policy-type="policyType" :form="form" :v="v" />

    <div v-if="policyType === constantsPolicyTypeAuto" class="row">
      <div class="col-lg-6">
        <div
          class="form-group mb-0"
          :class="isFieldInvalid('advisor') && 'invalid'"
        >
          <Select
            id="advisor"
            v-model="form.advisor"
            :options="advisors"
            label="Doradca"
            name="advisor"
          />
          <div v-if="isFieldInvalid('advisor')" class="invalid-feedback">
            Uzupełnij to pole
          </div>
        </div>
      </div>
    </div>
    <hr class="installments-divider" />

    <div class="row">
      <Collapse
        id="collapse-add-risk-on-policy"
        :collapsed="state.isRisksOnPolicyVisible"
        @onCollapse="handleCollapseRisksOnPolicy"
      >
        <template v-slot:heading>
          Chcę dodać informacje o ryzykach na polisie
        </template>
        <template v-slot:content>
          <div style="margin-top: 30px">
            <div
              v-for="(risk, index) in form.risksOnPolicy"
              :key="index"
              class="row align-items-center"
            >
              <div class="col-lg-4">
                <div
                  class="form-group"
                  :class="
                    isRiskOnPolicyFieldInvalid(index, 'name') && 'invalid'
                  "
                >
                  <Select
                    :id="'riskName-' + index"
                    v-model="risk.name"
                    :options="getRiskNames"
                    label="Ryzyko"
                    name="riskName"
                  />
                  <div
                    v-if="isRiskOnPolicyFieldInvalid(index, 'name')"
                    class="invalid-feedback"
                  >
                    Uzupełnij to pole
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div
                  class="form-group"
                  :class="
                    isRiskOnPolicyFieldInvalid(index, 'insuranceSum') &&
                      'invalid'
                  "
                >
                  <label :for="'insurance-sum-' + index">Suma ubezp.</label>
                  <input
                    :id="'insurance-sum-' + index"
                    v-model="risk.insuranceSum"
                    class="form-control"
                    type="number"
                    name="riskInsuranceSum"
                  />
                  <div
                    v-if="isRiskOnPolicyFieldInvalid(index, 'insuranceSum')"
                    class="invalid-feedback"
                  >
                    Uzupełnij to pole
                  </div>
                </div>
              </div>
              <div class="col-lg-1">
                <img
                  style="margin-top: 5px"
                  class="cursor-pointer"
                  src="/media/components/form/form-remove-element.svg"
                  alt=""
                  @click="handleRemoveRiskOnPolicy(index)"
                />
              </div>
            </div>
            <img
              class="cursor-pointer"
              src="/media/components/form/form-add-element.svg"
              alt=""
              @click="handleAddRiskOnPolicy"
            />
          </div>
        </template>
      </Collapse>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import _ from "lodash";
import Datepicker from "@/view/content/components/date-picker/Datepicker";
import Installments from "@/view/content/register-policy/steps/policy/Installments";
import Collapse from "@/view/content/components/collapse/Collapse";
import Select from "@/view/content/components/select/Select";
import { CONSTANTS } from "@/core/helpers/Constants";

export default {
  name: "Policy",
  components: { Select, Collapse, Datepicker, Installments },
  props: ["v", "form", "state", "policyType"],
  data() {
    return {
      riskNamesAuto: [
        { value: "", text: "Wybierz opcję" },
        { value: "oc", text: "OC" },
        { value: "ac", text: "AC" },
        { value: "nnw", text: "NNW" },
        { value: "assistance", text: "Assistance" }
      ],
      riskNamesMajatek: [
        { value: "", text: "Wybierz opcję" },
        {
          value: "elementy stałe od zniszczenia",
          text: "Elementy stałe od zniszczenia"
        },
        {
          value: "elementy stałe od kradzieży",
          text: "Elementy stałe od kradzieży"
        },
        { value: "mała architektura", text: "Mała architektura" },
        { value: "powódź", text: "Powódź" },
        { value: "OC w życiu prywatnym", text: "OC w życiu prywatnym" }
      ],
      riskNamesZycie: [
        { value: "", text: "Wybierz opcję" },
        { value: "ubezpieczenie na życie", text: "Ubezpieczenie na życie" },
        { value: "poważne zachorowania", text: "Poważne zachorowania" },
        { value: "nnw", text: "NNW" },
        { value: "pobyt w szpitalu", text: "Pobyt w szpitalu" }
      ],
      periodsAuto: [
        { text: "Wybierz", value: "" },
        { text: "1 rok", value: 1 },
        { text: "2 lata", value: 2 },
        { text: "3 lata", value: 3 },
        { text: "Inny", value: "other" }
      ],
      periodsMajatek: [
        { text: "Wybierz", value: "" },
        { text: "1 rok", value: 1 },
        { text: "2 lata", value: 2 },
        { text: "3 lata", value: 3 },
        { text: "Inny", value: "other" }
      ],
      periodsZycie: [
        { text: "Wybierz", value: "", isTop: true },
        { text: "1 rok", value: 1, isTop: false },
        { text: "2 lata", value: 2, isTop: false },
        { text: "3 lata", value: 3, isTop: false },
        { text: "4 lata", value: 4, isTop: false },
        { text: "5 lat", value: 5, isTop: true },
        { text: "6 lat", value: 6, isTop: false },
        { text: "7 lat", value: 7, isTop: false },
        { text: "8 lat", value: 8, isTop: false },
        { text: "9 lat", value: 9, isTop: false },
        { text: "10 lat", value: 10, isTop: true },
        { text: "11 lat", value: 11, isTop: false },
        { text: "12 lat", value: 12, isTop: false },
        { text: "13 lat", value: 13, isTop: false },
        { text: "14 lat", value: 14, isTop: false },
        { text: "15 lat", value: 15, isTop: true },
        { text: "16 lat", value: 16, isTop: false },
        { text: "17 lat", value: 17, isTop: false },
        { text: "18 lat", value: 18, isTop: false },
        { text: "19 lat", value: 19, isTop: false },
        { text: "20 lat", value: 20, isTop: true },
        { text: "21 lat", value: 21, isTop: false },
        { text: "22 lata", value: 22, isTop: false },
        { text: "23 lata", value: 23, isTop: false },
        { text: "24 lata", value: 24, isTop: false },
        { text: "25 lat", value: 25, isTop: false },
        { text: "26 lat", value: 26, isTop: false },
        { text: "27 lat", value: 27, isTop: false },
        { text: "28 lat", value: 28, isTop: false },
        { text: "29 lat", value: 29, isTop: false },
        { text: "30 lat", value: 30, isTop: false }
      ],
      productTypes: [
        { value: "", text: "Wybierz opcję" },
        { value: "rodzaj-1", text: "Rodzaj 1" },
        { value: "rodzaj-2", text: "Rodzaj 2" },
        { value: "rodzaj-3", text: "Rodzaj 3" }
      ],
      advisors: [
        { text: "Wybierz opcję", value: "" },
        { text: "Doradca 1", value: "doradca-1" },
        { text: "Doradca 2", value: "doradca-2" },
        { text: "Doradca 3", value: "doradca-3" }
      ]
    };
  },
  computed: {
    constantsPolicyTypeAuto() {
      return CONSTANTS().insuranceGroupAuto;
    },
    constantsPolicyTypeProperty() {
      return CONSTANTS().insuranceGroupProperty;
    },
    constantsPolicyTypeLife() {
      return CONSTANTS().insuranceGroupLife;
    },
    getPeriods() {
      switch (this.policyType) {
        case CONSTANTS().insuranceGroupAuto:
          return this.periodsAuto;
        case CONSTANTS().insuranceGroupProperty:
          return this.periodsMajatek;
        case CONSTANTS().insuranceGroupLife:
          return this.getPeriodsZycie;
        default:
          return this.periodsAuto;
      }
    },
    isDisabledEndDate() {
      return this.form.period !== "other";
    },
    getPeriodsZycie() {
      return [
        ..._.filter(this.periodsZycie, { isTop: true }),
        ..._.filter(this.periodsZycie, { isTop: false })
      ];
    },
    getRiskNames() {
      if (this.policyType === CONSTANTS().insuranceGroupAuto) {
        return this.riskNamesAuto;
      } else if (this.policyType === CONSTANTS().insuranceGroupProperty) {
        return this.riskNamesMajatek;
      } else if (this.policyType === CONSTANTS().insuranceGroupLife) {
        return this.riskNamesZycie;
      }

      return this.riskNamesAuto;
    }
  },
  watch: {
    v: {
      handler() {
        this.$nextTick(() => {
          for (let item in this.v) {
            if (this.v[item].hasOwnProperty("$reset")) {
              if (this.v[item].hasOwnProperty("$each")) {
                for (let each in this.v[item].$each.$iter) {
                  let currentItem = this.v[item].$each[each];
                  for (let item2 in currentItem) {
                    if (currentItem[item2].hasOwnProperty("$reset")) {
                      if (
                        (currentItem[item2].$model !== "" &&
                          currentItem[item2].$model !== null) ||
                        (currentItem[item2].$dirty &&
                          !currentItem[item2].$error &&
                          currentItem[item2].$model === "" &&
                          currentItem[item2].$model === null)
                      ) {
                        currentItem[item2].$reset();
                      }
                    }
                  }
                }
              } else {
                if (
                  (this.v[item].$model !== "" &&
                    this.v[item].$model !== null) ||
                  (this.v[item].$dirty &&
                    !this.v[item].$error &&
                    this.v[item].$model === "" &&
                    this.v[item].$model === null)
                ) {
                  this.v[item].$reset();
                }
              }
            }
          }
        });
      },
      deep: true
    }
  },
  methods: {
    isFieldInvalid(field) {
      return this.v[field].$dirty && this.v[field].$invalid;
    },
    isRiskOnPolicyFieldInvalid(index, field) {
      return (
        this.v.risksOnPolicy.$each[index][field].$dirty &&
        this.v.risksOnPolicy.$each[index][field].$invalid
      );
    },
    handleChangeDate(event, field) {
      const { value } = event.target;
      this.form[field] = value
        ? DateTime.fromFormat(value, CONSTANTS().dateYmdFormat).toFormat(
            CONSTANTS().dateYmdFormat
          )
        : "";
      if (field === "startDate") {
        this.handleUpdateEndDate();
      }
    },
    handleUpdateEndDate() {
      let startDate = DateTime.fromFormat(
        this.form.startDate,
        CONSTANTS().dateYmdFormat
      );
      let endDate = startDate
        .plus({ years: this.form.period })
        .minus({ days: 1 });
      this.form.endDate = endDate.toFormat(CONSTANTS().dateYmdFormat);
    },
    handleChangePeriod() {
      if (this.form.period !== "" && this.form.startDate !== "") {
        this.handleUpdateEndDate();
      }
    },
    handleChangeStartDate() {
      if (this.form.period !== "") {
        this.handleUpdateEndDate();
      }
    },
    handleCollapseRisksOnPolicy() {
      this.state.isRisksOnPolicyVisible = !this.state.isRisksOnPolicyVisible;
      if (this.form.risksOnPolicy.length < 1) {
        this.handleAddRiskOnPolicy();
      }
    },
    handleAddRiskOnPolicy() {
      this.form.risksOnPolicy.push({
        name: "",
        insuranceSum: ""
      });
      this.$nextTick(() => {
        this.$root.$emit("updateSection", "collapse-add-risk-on-policy");
      });
    },
    handleRemoveRiskOnPolicy(index) {
      this.form.risksOnPolicy.splice(index, 1);
      this.$nextTick(() => {
        this.$root.$emit("updateSection", "collapse-add-risk-on-policy");
      });
    }
  }
};
</script>
