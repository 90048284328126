export const CONSTANTS = () => {
  return {
    date_ddLLyyyy_Format: "dd-LL-yyyy",
    date_yyyyLLdd_Format: "yyyy-LL-dd",
    dateYmdFormat: "dd-LL-yyyy",
    dateYmdMask: "##-##-####",
    dateYmdDefault: "00-00-0000",
    dateYFormat: "yyyy",
    dateYMask: "####",
    dateYDefault: "0000",
    insuranceGroupAuto: "45EP5CFBN09ZRRDGSQ8MAJ8H8N",
    insuranceGroupLife: "2D3BS964VR9ZC9D3PCS3HTGJ2E",
    insuranceGroupProperty: "3EQYYX55T29X9RT9HRJ54FF2Q5",
    genderMan: "3BN9PP9ZWE8MPVN6TZAA287EK5",
    genderWoman: "7K7CHEARJH9KTAVQ18ZWN4KQHF",
    genderNone: "16G3638YW78ZHBTHJS8SSNG9JZ",
    customerTypeIndividual: "53STXEJRJN9ZWB5X4QP8Y6ZQS7",
    customerTypeSoleProprietorship: "34DX9HQPRX9M4A65YJV2NMT730",
    customerTypeCompany: "41386A238H9JST95Q2WXQ3NDNJ",
    subjectInsuranceAuto: "73T68ZE5TX8T68QBPSGKZ1GNDD",
    subjectInsuranceLife: "1QWVHC1VP296AB7TVJ23ZD81RS",
    subjectInsurancePersonal: "4VY9C63X8S9Y0R129GDBACPZFK",
    subjectInsuranceNone: "67KHA5AFT3982BZS411626JGVF"
  };
}