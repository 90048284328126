<template>
  <div>
    <div class="top"></div>
    <tabs>
      <template v-for="(client, index) in getClients">
        <tab
          :id="index"
          :key="index"
          :is-active="isClientStepActive(index)"
          :is-hidden="isRoleDisabled(index)"
          :title="getClientStepName(index)"
          :change="handleSetCurrentStep"
        >
          <ClientForm
            v-if="index !== 'coOwners' && index !== 'leasingOwner'"
            :client-form="form"
            :client-state="state"
            :form="client"
            :handle-set-current-step="handleSetCurrentStep"
            :insured-state="state.insured"
            :policy-type="policyType"
            :role="index"
            :state="state[index]"
            :v="v[index]"
            :v-insured="v.insured"
          />
          <template v-if="policyType === constantsPolicyTypeAuto && !state.isLeasing">
            <CoOwners
              v-if="index === 'coOwners'"
              :form="client"
              :policy-type="policyType"
              :state="state[index]"
              :v="v[index]"
              :whole-state="state"
              :whole-form="form"
            />
          </template>
          <template v-if="policyType === constantsPolicyTypeAuto && state.isLeasing">
            <ClientLeasingOwnerForm
              v-if="index === 'leasingOwner'"
              :form="client"
              :policy-type="policyType"
              :state="state[index]"
              :v="v[index]"
            />
          </template>
        </tab>
      </template>
    </tabs>
  </div>
</template>

<script>
import _ from "lodash";
import { scrollToElement } from "@/core/helpers/ScrollToElement";
import Tabs from "@/view/content/components/tabs/Tabs";
import Tab from "@/view/content/components/tabs/Tab";
import ClientForm from "@/view/content/register-policy/steps/client/form/ClientForm";
import CoOwners from "@/view/content/register-policy/steps/client/CoOwners";
import ClientLeasingOwnerForm from "@/view/content/register-policy/steps/client/form/ClientLeasingOwnerForm";
import { CONSTANTS } from "@/core/helpers/Constants";

export default {
  name: "Client",
  components: { ClientLeasingOwnerForm, CoOwners, ClientForm, Tabs, Tab },
  props: ["v", "state", "form", "policyType"],
  computed: {
    constantsPolicyTypeAuto() {
      return CONSTANTS().insuranceGroupAuto;
    },
    constantsPolicyTypeProperty() {
      return CONSTANTS().insuranceGroupProperty;
    },
    constantsPolicyTypeLife() {
      return CONSTANTS().insuranceGroupLife;
    },
    getClients() {
      if (this.policyType !== "") {
        if (this.policyType === this.constantsPolicyTypeAuto) {
          if (this.state.isLeasing) {
            return {
              insurer: this.form.insurer,
              user: this.form.user,
              leasingOwner: this.form.leasingOwner
            };
          } else {
            return {
              insurer: this.form.insurer,
              owner: this.form.owner,
              coOwners: this.form.coOwners
            };
          }
        } else {
          return {
            insurer: this.form.insurer,
            insured: this.form.insured
          };
        }
      } else {
        return [];
      }
    }
  },
  methods: {
    isClientStepActive(stepName) {
      return this.state.currentStep === stepName;
    },
    handleSetCurrentStep(step) {
      this.state.currentStep = step;
      scrollToElement(".top");
    },
    getClientStepName(shortName) {
      switch (shortName) {
        case "insurer":
          return "Ubezpieczający";
        case "insured":
          return "Ubezpieczony";
        case "user":
          return "Użytkownik";
        case "owner":
          return "Właściciel";
        case "leasingOwner":
          return "Właściciel";
        case "coOwners":
          return "Współwłaściciele";
      }
    },
    isRoleDisabled(role) {
      if (role === "insured") {
        if (this.policyType === this.constantsPolicyTypeLife) {
          return (
            _.includes(this.form.insurer.roles, role) &&
            !this.state.insured.hasChildren
          );
        } else {
          return _.includes(this.form.insurer.roles, role);
        }
      } else if (role === "owner") {
        return _.includes(this.form.insurer.roles, role);
      } else if (role === "user") {
        return _.includes(this.form.insurer.roles, role);
      }
    },
    resetClient(role) {
      this.form[role].legalForm = "";
      this.form[role].pesel = "";
      this.form[role].firstName = "";
      this.form[role].lastName = "";
      this.form[role].birthDate = "";
      this.form[role].phoneNumber = "";
      this.form[role].email = "";
      this.form[role].gender = "";
      this.form[role].companyName = "";
      this.form[role].nip = "";
      this.form[role].regon = "";
      this.form[role].roles = [role];
      if (role === "insured") {
        this.form[role].children = [];
      }
    }
  }
};
</script>
