<template>
  <div>
    <div class="form-group">
      <label>
        <template
          v-if="
            policyType === constantsPolicyTypeAuto ||
              policyType === constantsPolicyTypeProperty
          "
          >Liczba rat</template
        >
        <template v-else>Częstotliwość opłacania składki</template>
      </label>
      <div class="form-check-buttons">
        <template
          v-if="
            policyType === constantsPolicyTypeAuto ||
              policyType === constantsPolicyTypeProperty
          "
        >
          <div class="check">
            <input
              id="installmentsNumber-1"
              v-model="form.installmentsNumber"
              type="radio"
              name="installmentsNumber"
              :value="1"
              @change="handleChangeInstallmentsNumber"
            />
            <label for="installmentsNumber-1">1 rata</label>
          </div>
          <div class="check">
            <input
              id="installmentsNumber-2"
              v-model="form.installmentsNumber"
              type="radio"
              name="installmentsNumber"
              :value="2"
              @change="handleChangeInstallmentsNumber"
            />
            <label for="installmentsNumber-2">2 raty</label>
          </div>
          <div v-if="policyType === constantsPolicyTypeAuto" class="check">
            <input
              id="installmentsNumber-4"
              v-model="form.installmentsNumber"
              type="radio"
              name="installmentsNumber"
              :value="4"
              @change="handleChangeInstallmentsNumber"
            />
            <label for="installmentsNumber-4">4 raty</label>
          </div>
          <div v-if="policyType === constantsPolicyTypeProperty" class="check">
            <input
              id="installmentsNumber-3"
              v-model="form.installmentsNumber"
              type="radio"
              name="installmentsNumber"
              :value="3"
              @change="handleChangeInstallmentsNumber"
            />
            <label for="installmentsNumber-3">3 raty</label>
          </div>
        </template>
        <template v-else>
          <div class="check">
            <input
              id="paymentFrequency-1"
              v-model="form.paymentFrequency"
              type="radio"
              name="paymentFrequency"
              value="month"
              checked
              @change="handleChangePaymentFrequency"
            />
            <label for="paymentFrequency-1">miesięcznie</label>
          </div>
          <div class="check">
            <input
              id="paymentFrequency-2"
              v-model="form.paymentFrequency"
              type="radio"
              name="paymentFrequency"
              value="quarter"
              @change="handleChangePaymentFrequency"
            />
            <label for="paymentFrequency-2">kwartalnie</label>
          </div>
          <div class="check">
            <input
              id="paymentFrequency-3"
              v-model="form.paymentFrequency"
              type="radio"
              name="paymentFrequency"
              value="halfYear"
              @change="handleChangePaymentFrequency"
            />
            <label for="paymentFrequency-3">półrocznie</label>
          </div>
          <div class="check">
            <input
              id="paymentFrequency-4"
              v-model="form.paymentFrequency"
              type="radio"
              name="paymentFrequency"
              value="year"
              @change="handleChangePaymentFrequency"
            />
            <label for="paymentFrequency-4">rocznie</label>
          </div>
        </template>
      </div>
      <div v-if="isFieldInvalid('paymentFrequency')" class="invalid-feedback">
        Uzupełnij to pole
      </div>
      <div class="repeatable-fields installments">
        <div class="row">
          <template v-for="(installment, index) in v.installments.$model">
            <div :key="index" class="col-lg-6">
              <div class="d-flex">
                <div v-if="policyType !== constantsPolicyTypeLife" class="group-number">
                  <div v-if="policyType !== constantsPolicyTypeLife">{{ index + 1 }}</div>
                </div>
                <div
                  class="group-field"
                  :class="
                    isInstallmentsFieldInvalid(index, 'price') && 'invalid'
                  "
                >
                  <label :for="'installment-price-' + index">Składka</label>
                  <input
                    :id="'installment-price-' + index"
                    v-model="installment.price"
                    class="form-control"
                    type="number"
                    name="price"
                  />
                  <div
                    v-if="isInstallmentsFieldInvalid(index, 'price')"
                    class="invalid-feedback"
                  >
                    Uzupełnij to pole
                  </div>
                </div>
                <div
                  class="group-field"
                  :style="policyType === constantsPolicyTypeLife ? 'width: 250px' : ''"
                  :class="
                    isInstallmentsFieldInvalid(index, 'price') && 'invalid'
                  "
                >
                  <label :for="'installment-paymentDate-' + index">
                    <template v-if="policyType === constantsPolicyTypeLife"
                      >Termin pierwszej płatności</template
                    >
                    <template v-else>Termin</template>
                  </label>
                  <Datepicker
                    :id="'installment-paymentDate-' + index"
                    :form="installment"
                    name="paymentDate"
                    :opened="openPaymentDate && openPaymentDateIndex === index"
                    @openCalendar="handleOpenPaymentDate"
                    @onChange="
                      handleChangeDate(
                        $event.target.value,
                        'paymentDate',
                        index
                      )
                    "
                  />
                  <div
                    v-if="isInstallmentsFieldInvalid(index, 'paymentDate')"
                    class="invalid-feedback"
                  >
                    Uzupełnij to pole
                  </div>
                </div>
                <div
                  v-if="index === 0 && form.installmentsNumber > 1"
                  class="group-copy-btn"
                >
                  <img
                    class="cursor-pointer"
                    src="/media/components/form/form-copy.svg"
                    alt=""
                    @click="handleCopyInstallmentDate"
                  />
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import Datepicker from "@/view/content/components/date-picker/Datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pl";
import { CONSTANTS } from "@/core/helpers/Constants";

export default {
  name: "Installments",
  components: { Datepicker },
  props: ["policyType", "v", "form"],
  data() {
    return {
      openPaymentDate: false,
      openPaymentDateIndex: -1
    };
  },
  computed: {
    constantsPolicyTypeAuto() {
      return CONSTANTS().insuranceGroupAuto;
    },
    constantsPolicyTypeProperty() {
      return CONSTANTS().insuranceGroupProperty;
    },
    constantsPolicyTypeLife() {
      return CONSTANTS().insuranceGroupLife;
    }
  },
  methods: {
    handleCopyInstallmentDate() {
      if (this.form.installmentsNumber === 1) {
        return;
      }
      const baseDate = DateTime.fromFormat(
        this.form.installments[0].paymentDate,
        CONSTANTS().dateYmdFormat
      );
      if (!baseDate.isValid) {
        return;
      }
      let monthsToAdd = 0;
      switch (this.form.installmentsNumber) {
        case 1:
          monthsToAdd = 0;
          break;
        case 2:
          monthsToAdd = 6;
          break;
        case 3:
          monthsToAdd = 4;
          break;
        case 4:
          monthsToAdd = 3;
          break;
      }
      for (let i = 1; i < this.form.installments.length; i++) {
        this.form.installments[i].paymentDate = baseDate
          .plus({ months: monthsToAdd * i })
          .toFormat(CONSTANTS().dateYmdFormat);
      }
    },
    handleChangeDate(value, field, index) {
      this.form.installments[index][field] = value
        ? DateTime.fromFormat(value, CONSTANTS().dateYmdFormat).toFormat(
            CONSTANTS().dateYmdFormat
          )
        : "";
    },
    isInstallmentsFieldInvalid(index, field) {
      return (
        this.v.installments.$each[index][field].$dirty &&
        this.v.installments.$each[index][field].$invalid
      );
    },
    isFieldInvalid(field) {
      return this.v[field].$dirty && this.v[field].$invalid;
    },
    handleOpenPaymentDate(index) {
      this.openPaymentDate = true;
      this.openPaymentDateIndex = index;
    },
    handleChangeInstallmentsNumber(event) {
      const baseDate = DateTime.fromFormat(
        this.form.installments[0].paymentDate,
        CONSTANTS().dateYmdFormat
      );
      const { value: installmentsNumber } = event.target;
      let newInstallments = [];
      for (let i = 1; i <= Number(installmentsNumber); i++) {
        newInstallments.push({
          price: "",
          paymentDate:
            i === 1 && baseDate.isValid
              ? baseDate.toFormat(CONSTANTS().dateYmdFormat)
              : ""
        });
      }
      this.form.installments = newInstallments;
    },
    handleChangePaymentFrequency() {
      let newInstallments = [];

      if (this.form.installments.length === 0) {
        newInstallments.push({
          price: "",
          paymentDate: ""
        });
        this.form.installments = newInstallments;
        this.form.installmentsNumber = 1;
      }
    }
  }
};
</script>
